
import * as directusRuntime$4wNsDJIjuC from '/app/node_modules/.pnpm/@nuxt+image@1.9.0_ioredis@5.4.1_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as ipxRuntime$ZxRQgvqBJC from '/app/node_modules/.pnpm/@nuxt+image@1.9.0_ioredis@5.4.1_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['directus']: { provider: directusRuntime$4wNsDJIjuC, defaults: {"baseURL":"https://cms.starlinepool.com/assets/","formats":["avif","webp"],"modifiers":{"withoutEnlargement":true,"quality":60},"screens":{"xs":320,"sm":640,"md":768,"lg":1024,"xl":1280}} },
  ['ipx']: { provider: ipxRuntime$ZxRQgvqBJC, defaults: {} }
}
        